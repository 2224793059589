// export default function InvestorMarketingSection() {
//   const contentArray = [
//     "Has Lead",
//     "Product Stage",
//     "Regulatory Pathway",
//     "Deal Type",
//     "Amount",
//     "Pre-money Valuation",
//     "Post-money valuation",
//   ];

//   return (
//     <div className="w-[1440px] mx-auto flex flex-col relative">
//       <div className="w-full h-[739px] bg-gradient-to-b from-[#150943] to-[rgba(21,9,67,0.65)] flex relative">
//         <div className="bg-white text-black w-[150px] h-[389px] border border-gray-200 absolute left-[80px] top-[373px] rounded-bl-[8px]">
//           {contentArray.map((content, index) => (
//             <div
//               key={index}
//               className="p-2"
//               style={{
//                 color: "var(--gray-700, #374151)",
//                 fontSize: "12px",
//                 fontStyle: "normal",
//                 fontWeight: "400",
//                 lineHeight: "20px",
//                 marginBottom: "5px",
//               }}
//             >
//               {content}
//               <div
//                 style={{
//                   width: "115px",
//                   height: "13px",
//                   flexShrink: "0",
//                   borderRadius: "5px",
//                   border: "1px solid var(--gray-300, #D1D5DB)",
//                   background: "#EEE",
//                 }}
//               ></div>
//             </div>
//           ))}
//         </div>
//         <div className="absolute left-[80px] top-[288px] w-[650px] h-[85px] bg-white flex items-center rounded-t-[8px]">
//           <div
//             style={{
//               color: "#374151",
//               fontSize: "28px",
//               fontWeight: "600",
//               lineHeight: "20px",
//               marginLeft: "20px",
//             }}
//           >
//             <div className="mt-[36px] ml-[7px]">Deals</div>
//             <div className="flex justify-between p-2 mt-[24px]">
//             <div className="w-[41px] h-[4px] bg-[#150943]"></div>
//             <div className="w-[41px] h-[4px] bg-[#150943] ml-[19px]"></div>
//             <div className="w-[41px] h-[4px] bg-[#150943] ml-[19px]"></div>
//             </div>
//           </div>
//         </div>
//         <div className="bg-white text-black w-[500px] h-[389px] border border-gray-200 absolute left-[230px] top-[373px] rounded-br-[8px]">
//           <div className="flex items-center p-2">
//             <div className="w-[463px] h-[40px] bg-[#F1F2F3] border border-[#EEE] rounded-full flex items-center px-4">
//               <img src="/Search.svg" />
//             </div>
//           </div>
//           <div className="flex justify-between p-2 border-b border-gray-200">
//             <div className="w-[90px] h-[20px] bg-[#6B7280] p-2"></div>
//             <div className="w-[90px] h-[20px] bg-[#6B7280] p-2"></div>
//             <div className="w-[90px] h-[20px] bg-[#6B7280] p-2"></div>
//             <div className="w-[141px] h-[20px] bg-[#6B7280] p-2"></div>
//           </div>
//           {[...Array(5)].map((_, index) => (
//             <div key={index} className="flex h-[59px] justify-between items-center p-2 border-b border-gray-200">
//               <div className="w-[90px] h-[20px] bg-[#D1D5DB]"></div>
//               <div className="w-[90px] h-[20px] bg-[#D1D5DB]"></div>
//               <div className="w-[90px] h-[20px] bg-[#D1D5DB]"></div>
//               <button className="w-[90px] h-[30px] bg-[#150943] text-white rounded-md">
//                 View
//               </button>
//             </div>
//           ))}
//         </div>
//         <div className="flex-grow flex flex-col items-end justify-start pr-[48px]">
//           <img
//             src="/mti.svg"
//             alt="Logo"
//             className="w-[171px] h-[73px] mt-[60px] self-start ml-[48px] object-cover"
//             style={{ zIndex: 10 }}
//           />
//           <div className="text-right mt-[60px] relative">
//             <div className="text-[#FFF] font-inter font-extrabold text-[45px] leading-[60px]">
//               MEDTECH INNOVATOR&apos;S
//             </div>
//             <div className="text-[#FFF] font-inter font-light text-[45px] leading-[60px] mr-[120px]">
//               INVESTOR TOOLKIT
//             </div>
//             <p className="text-[#FFF] text-left font-inter font-normal text-[20px] leading-[28px] mt-4 w-[33%] ml-[61%]">
//               Comprehensive insights from dynamic companies that are part of the
//               world’s largest accelerator for medical device, digital health,
//               and diagnostic companies. Invest in companies that focus on
//               improving the lives of patients and are transforming the
//               healthcare system.
//             </p>
// <div
//   className="absolute top-[270px] right-[415px] mx-auto mt-[45px] inline-flex h-[30px] w-[126px] items-center justify-center rounded-[4px] bg-[#150943] px-[11px] py-[9px] text-white shadow-sm font-medium text-sm leading-4"
//   style={{
//     boxShadow:
//       "0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #FFF, 0px 0px 0px 4px #6366F1",
//   }}
// >
//   Purchase Now
// </div>
//           </div>
//         </div>
//       </div>
//       <div className="w-full h-[1210px] bg-[url('/background.png')] bg-cover bg-no-repeat bg-center flex flex-col items-center">
//         <div
//           className="mt-[232px] w-[596px] text-center"
//           style={{
//             color: "var(--gray-700, #374151)",
//             fontSize: "45px",
//             fontStyle: "normal",
//             fontWeight: "500",
//             lineHeight: "60px",
//           }}
//         >
//           Explore Company Details
//         </div>
//         <div
//           className="mt-4 w-[480px] text-center"
//           style={{
//             color: "var(--gray-700, #374151)",
//             fontSize: "16px",
//             fontStyle: "normal",
//             fontWeight: "500",
//             lineHeight: "24px",
//           }}
//         >
//           Delve into comprehensive company profiles, receiving exclusive insights into startup ventures alongside detailed product and deal data
//         </div>

//     <div className="mt-4 bg-white border border-gray-300 w-[695px] h-[774px]">
//       <div className="flex h-[46px] w-full items-center rounded-tl-[8px] rounded-tr-[8px] border border-gray-200">
//         <img src="/mti2.svg" className="ml-[9px] h-[50px] w-[100px]" />
//       </div>
//       <div className="flex flex-col h-[70px] w-full border border-gray-200">
//         <div className="bg-gray-200 h-[35px] w-[177px] mt-[8px] ml-[17px]"></div>
//         <div className="bg-gray-200 h-[15px] w-[177px] mt-[8px] ml-[17px] mb-2"></div>
//       </div>
//       <div className="flex flex-col h-[70px] w-full">
//         <div
//           className="mx-auto mt-[45px] inline-flex h-[30px] w-[126px] items-center justify-center rounded-[4px] bg-[#1651D9] px-[11px] py-[7px] text-white shadow-sm font-medium text-xs leading-4"
//           style={{
//             boxShadow:
//               "0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #FFF, 0px 0px 0px 4px #6366F1",
//           }}
//         >
//           Contact Company
//         </div>
//         <div
//           className="mx-auto mt-[15px] w-[156px] h-[10px] bg-gray-200"
//           style={{ flexShrink: "0" }}
//         ></div>
//         <div
//           className="mx-auto mt-[5px] w-[156px] h-[10px] bg-gray-200"
//           style={{ flexShrink: "0" }}
//         ></div>

//         <div className="ml-[35px] mt-[35px] font-medium text-xs leading-4 text-gray-700">
//           Company Details
//         </div>
//         <div
//           className="ml-[35px] mt-[5px] h-[10px] w-[450px] bg-gray-200 mb-4"
//           style={{ flexShrink: "0" }}
//         ></div>
//         <div className="ml-[35px] h-[40px] w-[620px] flex-shrink-0 rounded-t-[4px] border border-gray-200 bg-white flex justify-between p-2">
//           <div className="w-[120px] h-[20px] bg-[#6B7280] p-2"></div>
//           <div className="w-[120px] h-[20px] bg-[#6B7280] p-2"></div>
//           <div className="w-[120px] h-[20px] bg-[#6B7280] p-2"></div>
//           <div className="w-[188px] h-[20px] bg-[#6B7280] p-2"></div>
//         </div>
//         <div className="ml-[35px] h-[70px] w-[620px] flex-shrink-0 border border-gray-200 bg-white flex justify-between p-2">
//           <div className="w-[120px] h-[20px] bg-gray-300 p-2 mt-4"></div>
//           <div className="w-[120px] h-[20px] bg-gray-300 p-2 mt-4"></div>
//           <div className="w-[120px] h-[20px] bg-gray-300 p-2 mt-4"></div>
//           <div className="w-[188px] h-[20px] bg-white p-2 mt-4"></div>
//         </div>
//         <div className="ml-[35px] h-[208px] w-[620px] flex-shrink-0 border border-gray-200 bg-white flex justify-between p-2">
//           <div className="w-[156px] h-[65px] bg-white p-2 mt-4">
//             Amount
//             <img src="/4 lines.svg" />
//           </div>
//           <div className="w-[156px] h-[65px] bg-white p-2 mt-4">
//             Investors
//             <img src="/4 lines.svg" />
//           </div>
//           <div className="w-[156px] h-[65px] bg-white p-2 mt-4">
//             Deal Status
//             <img src="/4 lines.svg" />
//           </div>
//           <div className="w-[56px] h-[65px] bg-white p-2 mt-4"></div>
//         </div>
//         <div className="ml-[35px] h-[70px] w-[620px] flex-shrink-0 border border-gray-200 bg-white flex justify-between p-2">
//           <div className="w-[120px] h-[20px] bg-gray-300 p-2 mt-4"></div>
//           <div className="w-[120px] h-[20px] bg-gray-300 p-2 mt-4"></div>
//           <div className="w-[120px] h-[20px] bg-gray-300 p-2 mt-4"></div>
//           <div className="w-[188px] h-[20px] bg-white p-2 mt-4"></div>
//         </div>
//         <div className="ml-[35px] h-[70px] w-[620px] flex-shrink-0 rounded-b-[4px] border border-gray-200 bg-white flex justify-between p-2">
//           <div className="w-[120px] h-[20px] bg-gray-300 p-2 mt-4"></div>
//           <div className="w-[120px] h-[20px] bg-gray-300 p-2 mt-4"></div>
//           <div className="w-[120px] h-[20px] bg-gray-300 p-2 mt-4"></div>
//           <div className="w-[188px] h-[20px] bg-white p-2 mt-4"></div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
//   );
// }

export default function InvestorMarketingSection() {
  const contentArray = [
    "Has Lead",
    "Product Stage",
    "Regulatory Pathway",
    "Deal Type",
    "Amount",
    "Pre-money Valuation",
    "Post-money valuation",
  ];

  return (
    <div className="pt-6 w-[1440px] mx-auto flex flex-col relative">
      <div className="w-full h-[739px] bg-gradient-to-b from-[#150943] to-[rgba(21,9,67,0.65)] flex relative">
        <div className="bg-white text-black w-[150px] h-[389px] border border-gray-200 absolute left-[80px] top-[373px] rounded-bl-md">
          {contentArray.map((content, index) => (
            <div
              key={index}
              className="p-2 text-gray-700 text-xs font-normal leading-5 mb-1"
            >
              {content}
              <div className="w-[115px] h-[13px] flex-shrink-0 rounded-md border border-gray-300 bg-gray-200"></div>
            </div>
          ))}
        </div>
        <div className="absolute left-[80px] top-[288px] w-[650px] h-[85px] bg-white flex items-center rounded-t-md">
          <div className="text-gray-700 text-4xl font-semibold leading-5 ml-5">
            <div className="mt-[36px] ml-[7px]">Deals</div>
            <div className="flex justify-between p-2 mt-[24px]">
              <div className="w-[41px] h-[4px] bg-[#150943]"></div>
              <div className="w-[41px] h-[4px] bg-[#150943] ml-[19px]"></div>
              <div className="w-[41px] h-[4px] bg-[#150943] ml-[19px]"></div>
            </div>
          </div>
        </div>
        <div className="bg-white text-black w-[500px] h-[389px] border-b border-gray-200 absolute left-[230px] top-[373px] rounded-br-md">
          <div className="flex items-center p-2">
            <div className="w-[463px] h-[40px] bg-gray-200 border border-gray-200 rounded-full flex items-center px-4">
              <img src="/Search.svg" alt="Search" />
            </div>
          </div>
          <div className="flex justify-between p-2 border-b border-t border-gray-200">
            <div className="w-[90px] h-[20px] bg-gray-600 p-2"></div>
            <div className="w-[90px] h-[20px] bg-gray-600 p-2"></div>
            <div className="w-[90px] h-[20px] bg-gray-600 p-2"></div>
            <div className="w-[141px] h-[20px] bg-gray-600 p-2"></div>
          </div>
          {[...Array(5)].map((_, index) => (
            <div
              key={index}
              className="flex h-[59px] justify-between items-center p-2 border-b border-gray-200"
            >
              <div className="w-[90px] h-[20px] bg-gray-300"></div>
              <div className="w-[90px] h-[20px] bg-gray-300"></div>
              <div className="w-[90px] h-[20px] bg-gray-300"></div>
              <button className="w-[90px] h-[30px] bg-[#150943] text-white rounded-md">
                View
              </button>
            </div>
          ))}
        </div>
        <div className="flex-grow flex flex-col items-end justify-start pr-[48px]">
          <img
            src="/mti.svg"
            alt="Logo"
            className="w-[171px] h-[73px] mt-[60px] self-start ml-[48px] object-cover"
          />
          <div className="text-right mt-[60px] relative">
            <div className="text-white font-inter font-extrabold text-[45px] leading-[60px]">
              MEDTECH INNOVATOR&apos;S
            </div>
            <div className="text-white font-inter font-light text-[45px] leading-[60px] mr-[120px]">
              INVESTOR TOOLKIT
            </div>
            <p className="text-white text-left font-inter font-normal text-[20px] leading-[28px] mt-4 w-[33%] ml-[61%]">
              Comprehensive insights from dynamic companies that are part of the
              world&apos;s largest accelerator for medical device, digital
              health, and diagnostic companies. Invest in companies that focus
              on improving the lives of patients and are transforming the
              healthcare system.
            </p>
            {/* <div
              className="absolute top-[270px] right-[415px] mx-auto mt-[45px] inline-flex h-[30px] w-[126px] items-center justify-center rounded-[4px] bg-[#150943] px-[11px] py-[9px] text-white shadow-sm font-medium text-sm leading-4"
              style={{
                boxShadow:
                  "0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #FFF, 0px 0px 0px 4px #6366F1",
              }}
            >
              Purchase Now
            </div> */}
          </div>
        </div>
      </div>
      <div className="w-full h-[1210px] bg-[url('/background.png')] bg-cover bg-no-repeat bg-center flex flex-col items-center">
        <div className="mt-[232px] w-[596px] text-center text-gray-700 text-[45px] font-normal leading-[60px]">
          Explore Company Details
        </div>
        <div className="mt-4 w-[480px] text-center text-gray-700 text-base font-normal leading-6">
          Delve into comprehensive company profiles, receiving exclusive
          insights into startup ventures alongside detailed product and deal
          data
        </div>

        <div className="mt-4 bg-white border border-gray-300 w-[695px] h-[774px]">
          <div className="flex h-[46px] w-full items-center rounded-tl-[8px] rounded-tr-[8px] border border-gray-200">
            <img src="/mti2.svg" className="ml-[9px] h-[50px] w-[100px]" />
          </div>
          <div className="flex flex-col h-[70px] w-full border border-gray-200">
            <div className="bg-gray-200 h-[35px] w-[177px] mt-[8px] ml-[17px]"></div>
            <div className="bg-gray-200 h-[15px] w-[177px] mt-[8px] ml-[17px] mb-2"></div>
          </div>
          <div className="flex flex-col h-[70px] w-full">
            <div
              className="mx-auto mt-[45px] inline-flex h-[30px] w-[126px] items-center justify-center rounded-[4px] bg-[#1651D9] px-[11px] py-[7px] text-white shadow-sm font-medium text-xs leading-4"
              style={{
                boxShadow:
                  "0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #FFF, 0px 0px 0px 4px #6366F1",
              }}
            >
              Contact Company
            </div>
            <div className="mx-auto mt-[15px] w-[156px] h-[10px] bg-gray-200 flex-shrink-0"></div>
            <div className="mx-auto mt-[5px] w-[156px] h-[10px] bg-gray-200 flex-shrink-0"></div>

            <div className="ml-[35px] mt-[35px] font-medium text-xs leading-4 text-gray-700">
              Company Details
            </div>
            <div className="ml-[35px] mt-[5px] h-[10px] w-[450px] bg-gray-200 mb-4 flex-shrink-0"></div>
            <div className="ml-[35px] h-[40px] w-[620px] flex-shrink-0 rounded-t-[4px] border border-gray-200 bg-white flex justify-between p-2">
              <div className="w-[120px] h-[20px] bg-[#6B7280] p-2"></div>
              <div className="w-[120px] h-[20px] bg-[#6B7280] p-2"></div>
              <div className="w-[120px] h-[20px] bg-[#6B7280] p-2"></div>
              <div className="w-[188px] h-[20px] bg-[#6B7280] p-2"></div>
            </div>
            <div className="ml-[35px] h-[70px] w-[620px] flex-shrink-0 border border-gray-200 bg-white flex justify-between p-2">
              <div className="w-[120px] h-[20px] bg-gray-300 p-2 mt-4"></div>
              <div className="w-[120px] h-[20px] bg-gray-300 p-2 mt-4"></div>
              <div className="w-[120px] h-[20px] bg-gray-300 p-2 mt-4"></div>
              <div className="w-[188px] h-[20px] bg-white p-2 mt-4"></div>
            </div>
            <div className="ml-[35px] h-[208px] w-[620px] flex-shrink-0 border border-gray-200 bg-white flex justify-between p-2">
              <div className="w-[156px] h-[65px] bg-white p-2 mt-4">
                Amount
                <img src="/4 lines.svg" />
              </div>
              <div className="w-[156px] h-[65px] bg-white p-2 mt-4">
                Investors
                <img src="/4 lines.svg" />
              </div>
              <div className="w-[156px] h-[65px] bg-white p-2 mt-4">
                Deal Status
                <img src="/4 lines.svg" />
              </div>
              <div className="w-[56px] h-[65px] bg-white p-2 mt-4"></div>
            </div>
            <div className="ml-[35px] h-[70px] w-[620px] flex-shrink-0 border border-gray-200 bg-white flex justify-between p-2">
              <div className="w-[120px] h-[20px] bg-gray-300 p-2 mt-4"></div>
              <div className="w-[120px] h-[20px] bg-gray-300 p-2 mt-4"></div>
              <div className="w-[120px] h-[20px] bg-gray-300 p-2 mt-4"></div>
              <div className="w-[188px] h-[20px] bg-white p-2 mt-4"></div>
            </div>
            <div className="ml-[35px] h-[70px] w-[620px] flex-shrink-0 rounded-b-[4px] border border-gray-200 bg-white flex justify-between p-2">
              <div className="w-[120px] h-[20px] bg-gray-300 p-2 mt-4"></div>
              <div className="w-[120px] h-[20px] bg-gray-300 p-2 mt-4"></div>
              <div className="w-[120px] h-[20px] bg-gray-300 p-2 mt-4"></div>
              <div className="w-[188px] h-[20px] bg-white p-2 mt-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
