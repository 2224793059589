"use client";
import Layout from "./components/Layout";
import { useContext, useEffect } from "react";
import {
  ReadonlyURLSearchParams,
  useRouter,
  useSearchParams,
} from "next/navigation";

import InvestorMarketingSection from "./components/marketing/InvestorMarketing";
import { UserContext } from "./utils/user";
import withNoSSR from "./withNoSSR";
import { UsersIcon, DocumentChartBarIcon } from "@heroicons/react/24/outline";
import { role } from "./types";
import { classNames } from "./utils/tailwindHelper";
import { VideoCameraIcon } from "@heroicons/react/20/solid";
import Link from "next/link";
import { showNewMessage } from "@intercom/messenger-js-sdk";
import OmniSearch from "./components/search/OmniSearch";
import toast from "react-hot-toast";

const Home = function () {
  const router = useRouter();
  const user = useContext(UserContext).user;
  const storeUser = useContext(UserContext).storeUser;
  if (!user) {
    router.push("/login");
  }

  useEffect(() => {
    const fetchData = async () => {
      const authResponse = await fetch(
        `${process.env.NEXT_PUBLIC_BACKEND_URL}/auth/whoami`,
        {
          credentials: "include",
          redirect: "error",
        }
      );

      if (authResponse.status > 400) {
        toast.error("Invalid email or password");
        return false;
      }
      const user = await authResponse.json();
      await storeUser(user);
    };
    fetchData();
  }, []);

  const adminNavigation = [
    {
      name: "All Users",
      href: "/admin/users/all",
      icon: UsersIcon,
      current: false,
    },
    {
      name: "Portfolio Users",
      href: "/admin/users/portfolio",
      icon: UsersIcon,
      current: false,
    },
    {
      name: "Airtable PortCo Sync",
      href: "/admin/users/airtable-portfolio",
      icon: UsersIcon,
      current: false,
    },
    {
      name: "Investor Users",
      href: "/admin/users/investor",
      icon: UsersIcon,
      current: false,
    },
    {
      name: "Company Diligence",
      href: "/admin/diligence",
      icon: DocumentChartBarIcon,
      current: false,
    },
    {
      name: "Video Categorization",
      href: "/admin/videos",
      icon: VideoCameraIcon,
      current: false,
    },
    {
      name: "Portfolio Video List",
      href: "/admin/portfolio/videos",
      icon: VideoCameraIcon,
      current: false,
    },
  ];

  if (user) {
    // const handleSave = async (role: role) => {
    //   const result = await updateUserInfo({
    //     id: user.id,
    //     role,
    //   });
    //   if (result) {
    //     toast.success("Updated successfully");
    //   } else {
    //     toast.error("Failed to update user information");
    //   }
    //   window.location.reload();
    // };

    ///* ;}>Throw error</button> */
    return (
      <main>
        {/* @ts-ignore */}
        <Layout>
          <div className="flex flex-col mt-12">
            <div className="flex justify-center text-2xl">
              Welcome {user?.firstName}
            </div>

            {!user.role && (
              <div className="mx-auto pt-8 max-w-2xl text-center">
                You aren&apos;t yet fully configured or associated with a
                company record. Please reach out over{" "}
                <a
                  href={`slack://user?team=T5R1Z6UE6&id=U05Q0EG9NV7`}
                  target="_blank"
                  className="font-semibold text-blue-600 hover:text-blue-500"
                >
                  slack
                </a>{" "}
                or email{" "}
                <a
                  href="mailto:support@medtechinnovator.org"
                  className="font-semibold text-blue-600 hover:text-blue-500"
                >
                  support@medtechinnovator.org
                </a>
                . We may request additional information to verify this login.
              </div>
            )}
            {user.role && user.role == role.partner && (
              <div>
                <div className="mx-auto max-w-7xl px-6 py-16 lg:px-8">
                  <div className="mx-auto max-w-2xl text-center">
                    <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
                      Partner Resources
                    </h2>
                    <p className="mt-6 text-base leading-7 text-gray-600">
                      Need help or having issues with this site? The easiest way
                      to reach out is{" "}
                      <a
                        href={`slack://user?team=T5R1Z6UE6&id=U05Q0EG9NV7`}
                        target="_blank"
                        className="font-semibold text-blue-600 hover:text-blue-500"
                      >
                        slack
                      </a>{" "}
                      and we will get back to you as soon as we can. For all
                      program related inquiries, please email{" "}
                      <a
                        href="mailto:support@medtechinnovator.org"
                        className="font-semibold text-blue-600 hover:text-blue-500"
                      >
                        support@medtechinnovator.org
                      </a>
                      .
                    </p>
                  </div>
                  <div className="mt-20">
                    <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
                      <div>
                        <dt className="text-base font-semibold leading-7 text-gray-900">
                          Portfolio Directory
                        </dt>
                        <dd className="mt-2 text-base leading-7 text-gray-600">
                          <p>
                            Comprehensive list of all Innovator Portfolio
                            companies and contact information.
                          </p>
                          <p>
                            This directory is powered by live data provided by
                            the companies themselves.
                          </p>
                        </dd>
                      </div>
                      <div>
                        <dt className="text-base font-semibold leading-7 text-gray-900"></dt>
                        <dd className="mt-2 text-base leading-7 text-gray-600">
                          <p></p>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            )}
            {user?.role == role.admin && (
              <div className="mx-auto">
                <div className="m-8">You are logged in as an admin.</div>
                <OmniSearch />
                <nav className="flex flex-1 flex-col" aria-label="Sidebar">
                  <ul role="list" className="-mx-2 space-y-1">
                    {adminNavigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-50 text-blue-600"
                              : "text-gray-700 hover:text-blue-600 hover:bg-gray-50",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-blue-600"
                                : "text-gray-400 group-hover:text-blue-600",
                              "h-6 w-6 shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
                {/* <Link href="/admin">Admin</Link> */}
              </div>
            )}
            {user?.role == role.company && (
              <>
                {user?.company?.cohortMembership?.length > 0 ? (
                  <div>
                    <div className="mx-auto max-w-7xl px-6 py-16 lg:px-8">
                      <div className="mx-auto max-w-2xl text-center">
                        <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
                          Portfolio Company Resources
                        </h2>
                        <p className="mt-6 text-base leading-7 text-gray-600">
                          Need help or having issues with this site? The easiest
                          way to reach out is{" "}
                          <a
                            href={`slack://user?team=T5R1Z6UE6&id=U05Q0EG9NV7`}
                            target="_blank"
                            className="font-semibold text-blue-600 hover:text-blue-500"
                          >
                            slack
                          </a>{" "}
                          and we will get back to you as soon as we can. For all
                          program related inquiries, please email{" "}
                          <a
                            href="mailto:support@medtechinnovator.org"
                            className="font-semibold text-blue-600 hover:text-blue-500"
                          >
                            support@medtechinnovator.org
                          </a>
                          .
                        </p>
                      </div>
                      <div className="mt-20">
                        <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
                          {process.env.NODE_ENV === "development" && (
                            <div>
                              <dt className="text-base font-semibold leading-7 text-gray-900">
                                Open Round
                              </dt>
                              <dd className="mt-2 text-base leading-7 text-gray-600">
                                <p>
                                  Open Rounds is your secret weapon to raise
                                  capital faster and from better investors. We
                                  exclusively provide subscribing Investors with
                                  details on Open Rounds within our Portfolio.
                                  You control exactly what investors can see
                                  about your company, product, and Open Round.
                                  This information should be reviewed and
                                  updated as necessary. The following categories
                                  of information should be completed to ensure
                                  you match filtering criteria we provide
                                  investors.
                                </p>
                                <Link
                                  href={`/company/${user.companyId}/open-round`}
                                  className="font-semibold text-blue-600 hover:text-blue-500"
                                >
                                  Open Round
                                </Link>
                              </dd>
                            </div>
                          )}
                          <div>
                            <dt className="text-base font-semibold leading-7 text-gray-900">
                              Portfolio Directory
                            </dt>
                            <dd className="mt-2 text-base leading-7 text-gray-600">
                              <p>
                                Comprehensive list of all <strong>2024</strong>{" "}
                                Innovator Portfolio companies and contact
                                information.
                              </p>
                              <p>
                                You can update your profile and company
                                information to be included in the directory.
                              </p>
                              <Link
                                href={`/portfolio-directory`}
                                className="font-semibold text-blue-600 hover:text-blue-500"
                              >
                                Portfolio Directory
                              </Link>
                            </dd>
                          </div>
                          {/* <div>
                        <dt className="text-base font-semibold leading-7 text-gray-900">
                          Leadership Pods Voting
                        </dt>
                        <dd className="mt-2 text-base leading-7 text-gray-600">
                          <p>
                            Each 2024 Innovator cohort company will join a
                            Leadership Pod. To assist in creating the ideal
                            leadership pods, please navigate to the{" "}
                            <a
                              href="/forum/general"
                              className="font-semibold text-blue-600 hover:text-blue-500"
                              target="_blank"
                            >
                              General Forum
                            </a>{" "}
                            and select the topic that represents your program
                            (US, BioTools, APAC).
                          </p>
                        </dd>
                      </div> */}
                          <div>
                            <dt className="text-base font-semibold leading-7 text-gray-900">
                              Slack - Quick Questions and Conversations
                            </dt>
                            <dd className="mt-2 text-base leading-7 text-gray-600">
                              <p>
                                Medtech Innovator Slack is the best place to
                                communicate in real time with other your
                                year&apos;s Cohort, all Alumni, and the extended
                                MedTech / BioTools Innovator ecosystem including
                                our partners, investors, and program judges. The
                                channels are invite-only channels, please let us
                                know if you did not receive an invitation to the
                                channels mentioned here.
                              </p>
                              <p>
                                <a
                                  href="https://join.slack.com/t/medtechinnovator/signup"
                                  className="font-semibold text-blue-600 hover:text-blue-500"
                                  target="_blank"
                                >
                                  Join Here
                                </a>
                              </p>
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col items-center py-8 mx-auto">
                    <p className="py-8">
                      2025 Applications are not yet open...
                    </p>
                    {`${process.env.NODE_ENV}` === "development" && (
                      <div className="flex mx-auto flex-col">
                        <p>
                          Not sure if the accelerator program is right for you?
                          Please visit our application landing page HERE.{" "}
                        </p>
                        <p>
                          Before applying to our program(s), you must first
                          create or associate with a company record.
                        </p>
                        <div className="flex mx-auto">
                          <input
                            type="text"
                            placeholder="Start typing your company name"
                          />
                        </div>
                        <p>
                          If no company record exists, show create company
                          button
                        </p>
                        <p>
                          If a company matches, tell them to contact Brian
                          B***** at COMPANY NAME
                        </p>
                        <button>Click here Request To Join COMPANY</button>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
            {user?.role && user.role == role.investor && (
              <div>
                {/* {!user.company ? (
                  <div className="flex py-8">
                    <div className="mx-auto">
                      To get started, you must first create a company record.
                      <NewCompany />
                    </div>
                  </div>
                ) : ( */}
                <>
                  {!user.openRoundsAccess && (
                    <div className="flex py-8 flex-col text-center">
                      <p>You are not yet configured for Open Rounds access.</p>
                      <p>
                        Please reach out to support at{" "}
                        <a
                          href="mailto:support@medtechinnovator.org"
                          className="font-semibold text-blue-600 hover:text-blue-500"
                        >
                          support@medtechinnovator.org
                        </a>{" "}
                        or{" "}
                        <a
                          className="font-semibold text-blue-600 hover:text-blue-500 hover:cursor-pointer"
                          onClick={() => {
                            showNewMessage(
                              "Hi, I'm an investor and need help getting access to Open Rounds."
                            );
                          }}
                        >
                          chat with us
                        </a>
                        .
                      </p>
                    </div>
                  )}
                </>
                {/* )} */}
                <div className="hidden lg:block">
                  <InvestorMarketingSection />
                </div>
              </div>
            )}
          </div>
        </Layout>
      </main>
    );
  } else {
    return <></>;
  }
};
export default withNoSSR(Home);
