import { makeAPIRequest } from "@/app/api/apiWrapper";
import useDebounce from "@/app/utils/debouce";
import { useEffect, useState } from "react";
import OmniSearchResult from "./OmniSearchResult";
import Loading from "../Loading";

export default function OmniSearch() {
  const [value, setValue] = useState<string>();
  const [results, setResults] = useState<any[]>();
  const [loading, setLoading] = useState(false);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);
  };

  const debouncedSearch = useDebounce(value, 200);

  const fetchData = async () => {
    setLoading(true);
    setResults([]);
    const results = await makeAPIRequest(
      `/search?keyword=${debouncedSearch}`,
      "GET"
    );
    setResults(results);
    setLoading(false);
  };

  useEffect(() => {
    if (debouncedSearch && debouncedSearch.length > 2) {
      fetchData();
    } else {
      setResults([]);
    }
  }, [debouncedSearch]);

  return (
    <div className="relative py-4">
      <div className="relative mt-2 flex items-center">
        <input
          id="search"
          name="search"
          type="text"
          placeholder="Search by name or keyword"
          value={value || ""}
          onChange={onChange}
          className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
        <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
          <kbd className="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400">
            ⌘K
          </kbd>
        </div>
      </div>
      {(loading || (results && results.length > 0)) && (
        <div className="absolute w-screen left-[140px] -translate-x-1/2 z-10 pt-2">
          <div className="w-fit min-w-64 mx-auto pointer-events-auto rounded-lg bg-white text-[0.8125rem] leading-5 text-slate-700 shadow-xl shadow-black/5 ring-1 ring-slate-700/10">
            {loading && (
              <div className="py-8">
                <Loading />
              </div>
            )}
            {results && results.length > 0 && (
              <div className="px-3.5 py-3">
                {results?.map((result) => (
                  <OmniSearchResult key={result._id} result={result} />
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
